import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CometChatIncomingCall = _resolveComponent("CometChatIncomingCall")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          style: _normalizeStyle(_ctx.computedStyle)
        }, [
          _createVNode(_component_router_view)
        ], 4)
      ]),
      _: 1
    })),
    _createVNode(_component_CometChatIncomingCall)
  ], 64))
}